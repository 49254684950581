//src/pages/MaintenancePage/MaintenancePage.js
import React from 'react';
import { motion } from 'framer-motion';

const MaintenancePage = () => {
  return (
    <div className="min-h-screen bg-background text-text flex flex-col items-center justify-center">
      {/* Background */}
      <div className="absolute inset-0 z-0 bg-gradient-to-r from-primary to-secondary" />

      {/* Content */}
      <div className="relative z-10 text-center px-4">
        <motion.h1
          className="text-5xl md:text-6xl font-bold text-white mb-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          We're Making Improvements!
        </motion.h1>
        <motion.p
          className="text-lg md:text-xl text-white mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Our website is currently undergoing maintenance to serve you better.
          Please check back soon!
        </motion.p>
        <motion.div
          className="bg-primary text-white py-3 px-6 rounded-full font-bold shadow-lg"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ repeat: Infinity, duration: 1, repeatType: "reverse" }}
        >
          Thank you for your patience!
        </motion.div>
      </div>
    </div>
  );
};

export default MaintenancePage;
