// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';
import ChatbotLayout from './layouts/ChatbotLayout';
// Pages
import LandingPage from './pages/LandingPage/LandingPage';
import HomePage from './pages/HomePage/HomePage';
import AccountPage from './pages/AccountPage/AccountPage';
import LoginPage from './pages/LoginPage/LoginPage';
import ChatbotPage from './pages/ChatbotPage/ChatbotPage';
import AboutUs from './pages/AboutUs/AboutUs';
import OurTechnology from './pages/OurTechnology/OurTechnology';
import { SuggestionBox } from './components/feedbacks';

import MaintenancePage from './pages/MaintenancePage/MaintenancePage'; // Import the maintenance page

// Import Joyride and necessary modules
import Joyride, { STATUS } from 'react-joyride';
import { updateUserData } from './services/api';

import './styles/App.css';

// Import Firebase analytics
import { analytics } from './firebase';

// Import React Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AppContent() {
  const isMaintenanceMode = true; // Toggle this to enable or disable maintenance mode

  const location = useLocation();
  const { currentUser, userData, setUserData } = useAuth();

  // Joyride state
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    // Log page view event
    analytics.logEvent('page_view', {
      page_path: location.pathname,
      page_location: window.location.href,
      page_title: document.title,
    });
  }, [location]);

  // Function to determine if SuggestionBox should be displayed
  const shouldShowSuggestionBox = () => {
    const isLoggedIn = !!currentUser;
    const isChatbotOrLogin = location.pathname === '/chatbot' || location.pathname === '/login';
    return isLoggedIn && !isChatbotOrLogin; // Show only when logged in and not on /chatbot or /login
  };

  // Joyride steps
  const steps = [
    {
      target: '.nav-home',
      content: 'This is the Home page link where you can access your dashboard.',
      disableBeacon: true,
      placement: 'bottom',
    },
    {
      target: '.nav-myskin',
      content: 'Access your skin profile and preferences here.',
      placement: 'bottom',
    },
    {
      target: '.nav-chatbot',
      content: 'Chat with your AI skincare assistant anytime!',
      placement: 'bottom',
    },
  ];

  useEffect(() => {
    if (userData && !userData.tourCompleted && userData.onboardingCompleted) {
      console.log('Starting Joyride tour');
      // Delay starting the tour to ensure elements are rendered
      setTimeout(() => {
        setRunTour(true);
      }, 500); // Adjust delay as needed
    } else {
      console.log('Conditions not met for starting Joyride:', {
        userData,
        tourCompleted: userData?.tourCompleted,
        onboardingCompleted: userData?.onboardingCompleted,
      });
    }
  }, [userData]);

  const handleJoyrideCallback = async (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunTour(false);
      try {
        await updateUserData({ tourCompleted: true });
        const updatedUserData = { ...userData, tourCompleted: true };
        setUserData(updatedUserData);
        console.log('User data after tour completion:', updatedUserData);
      } catch (error) {
        console.error('Error updating tour status:', error);
      }
    }

    if (type === 'step:after') {
      setStepIndex(data.index + 1);
    }
  };

  if (isMaintenanceMode) {
    return (
      <div className="App">
        <Routes>
          <Route path="*" element={<MaintenancePage />} />
        </Routes>
      </div>
    );
  }

  return (
    <div className="App">
      {/* Joyride Component */}
      <Joyride
        steps={steps}
        run={runTour}
        stepIndex={stepIndex}
        continuous
        showSkipButton
        showProgress
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        callback={handleJoyrideCallback}
      />

      <Routes>
        {/* Chatbot route with custom layout */}
        <Route
          path="/chatbot"
          element={
            <PrivateRoute>
              <ChatbotLayout>
                <ChatbotPage />
              </ChatbotLayout>
            </PrivateRoute>
          }
        />

        {/* All other routes with standard layout */}
        <Route
          path="*"
          element={
            <>
              <Navigation />
              <main className="main-content">
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    path="/home"
                    element={
                      <PrivateRoute>
                        <HomePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/account"
                    element={
                      <PrivateRoute>
                        <AccountPage />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/technology" element={<OurTechnology />} />
                  <Route path="*" element={<Navigate to="/home" />} />
                </Routes>
              </main>
              <Footer />
              {shouldShowSuggestionBox() && <SuggestionBox />}
            </>
          }
        />
      </Routes>
      {/* React Toastify Container */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
